import { DataTable } from "simple-datatables";

document.addEventListener("turbolinks:load", () => {
  const DATA_TABLES = [...document.querySelectorAll(".infotable")];

  DATA_TABLES.forEach((item) => {
    new DataTable(item, {
      perPageSelect: [10, 50, 100, 1000],
      perPage: 50,
    });
  });
});
